<script setup lang="ts"></script>

<template>
  <div>
    <div class="text-sm mb-6 pr-10 lg:pr-0">
      <h1 class="text-black text-lg">How Rewarding works</h1>
      <p class="mt-6">
        If you pass the initial campaign eligibility criteria, you are then able
        to earn rewards by posting the message defined in the campaign
        description.
      </p>
      <p class="mt-6">
        Then depending on the quality of your posts and number of impressions,
        your eligible post will be rewarded by a share of a campaign allocated
        budget approximately 1 week after the campaign ends.
      </p>
      <p class="mt-6 text-[#6659FF]">
        Only organic and authentic impressions are being considered in the
        rewarding process. Artificial boosting like cross-promotion groups and
        similar engagement farms is not allowed.
      </p>

      <h2 class="mt-6 text-md">FAQ</h2>

      <p class="mt-6 text-gray-400">Why were my tweets slashed?</p>

      <p class="mt-6">
        We have detected a suspicious activity on your tweets. For the final
        rewards, we exclude all artificial boosting, to ensure a fair reward
        distribution to authentic crypto audience. Artificial boosting includes
        cross-promotion groups and similar shilling communities.
      </p>

      <p class="mt-6 text-gray-400">How to get higher tweet quality?</p>

      <p class="mt-6">
        The tips to achieve high quality of posts can be seen in the campaign
        detail. Try to be creative with the posts, use features like polls to
        drive more engagement.
      </p>

      <p class="mt-6 text-gray-400">How to get higher tweet quality?</p>

      <p class="mt-6">
        A good way to increase your organic reach is to be more active in the
        crypto community, engaging with the authentic crypto audience.
      </p>

      <p class="mt-6 text-gray-400">
        What is my maximum reach that you pay me?
      </p>

      <p class="mt-6">
        We do not disclose the specific algorithm of how we estimate the size of
        authentic crypto audience for each tweet. We do not want to incentivize
        people to game it, but to behave fairly and engage the community.
      </p>
    </div>
  </div>
</template>
