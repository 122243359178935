<script setup lang="ts">
import type { SuggestedCampaignDto } from "~~/_api_generated_";

const campaign = ref(undefined as SuggestedCampaignDto | undefined);
const seeCriteria = ref(false);

function handleSidebar(event: {
  campaignToOpen: SuggestedCampaignDto | undefined;
  seeCriteria: boolean;
}) {
  seeCriteria.value = event.seeCriteria;
  campaign.value = event.campaignToOpen;
}

onMounted(() => {
  globalOn("campaignForSidebarClicked", handleSidebar);
});

onBeforeUnmount(() => {
  globalOff("campaignForSidebarClicked", handleSidebar);
});
</script>

<template>
  <Sidebar v-if="campaign" @close="campaign = undefined">
    <CampaignSidebar :campaign="campaign" :see-criteria="seeCriteria" />
  </Sidebar>
</template>
