<script setup lang="ts">
import type { SuggestedCampaignDto } from "~~/_api_generated_";
import EvangelistIcon from "../Icons/EvangelistIcon.vue";
import type { Brand } from "~/advertisorsData/shared/types";
import { getBrand } from "~/advertisorsData/shared/getBrand";

const emit = defineEmits(["closeAndRefresh"]);

interface Props {
  campaign: SuggestedCampaignDto;
  seeCriteria?: boolean;
}
const props = defineProps<Props>();

const advertiserData = ref<Brand>();

const hasAdditionalWalletChecks = computed(() => {
  return props.campaign.eligibility.restrictions.some(
    (restriction) => restriction.content === "Additional wallet checks",
  );
});

const walletAddress = computed(() => {
  return props.campaign.eligibility.lastNonEligibleWalletCaipAddress
    ? props.campaign.eligibility.lastNonEligibleWalletCaipAddress
        .split(":")
        .pop() || ""
    : "";
});

function onWalletSaved() {
  globalEmit("walletSaved", props.campaign);
}

onMounted(async () => {
  advertiserData.value = await getBrand(props.campaign.advertiser.theme);
});
</script>

<template>
  <div>
    <div class="text-lg mb-6 pr-10 lg:pr-0">
      <p class="text-gray-400">Creator eligibility criteria for:</p>
      <p>{{ campaign.title }}</p>
    </div>

    <div
      v-if="
        campaign.eligibility.state === 'NotEligible' ||
        campaign.eligibility.state === 'NotEligibleBasic'
      "
    >
      <div v-if="hasAdditionalWalletChecks && seeCriteria === false">
        <div class="text-black/50 flex items-center gap-3 flex-wrap">
          <BrandsIconAndName :theme="campaign?.advertiser?.theme" />
          <span class="w-1 h-1 rounded-full bg-black/50"></span>
          <CampaignDateInfo :campaign="campaign" />
          <span
            v-if="campaign.signupBonus > 0"
            class="w-1 h-1 rounded-full bg-black/50"
          ></span>
          <div
            v-if="campaign.apyBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-xs bg-[#CFCBFF] text-black"
          >
            <IconsShieldIcon class="mr-[4px] text-black fill-black" />
            Verified Members
          </div>
          <div
            v-if="campaign.signupBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-black text-xs bg-gray-300"
          >
            {{ campaign.signupBonus }} {{ campaign.budgetCurrency }} Sign up
            Bonus
          </div>
        </div>

        <div v-if="advertiserData" class="mt-8">
          <p
            :class="[i === 0 ? '' : 'mt-4']"
            v-for="(criteriaDescription, i) of advertiserData
              .eligibilityDescriptions['not-eligible']"
            :key="i"
          >
            {{ criteriaDescription }}
          </p>
        </div>
        <p
          class="mt-6 text-[#6659FF]"
          v-if="campaign.eligibility.lastNonEligibleWalletCaipAddress"
        >
          Wallet {{ walletAddress }} you tried verifying with is not eligible,
          please try again.
        </p>
      </div>
      <div v-if="!hasAdditionalWalletChecks || seeCriteria">
        To join this campaign, advertiser set following criteria for creators to
        be eligible:

        <ul class="list-disc px-5 mt-6">
          <li
            v-for="(restriction, restrictionIndex) in campaign.eligibility
              .restrictions"
            :key="restrictionIndex"
          >
            {{ restriction.content }}
          </li>
        </ul>
      </div>

      <a
        v-if="
          !hasAdditionalWalletChecks ||
          campaign.eligibility.state === 'NotEligibleBasic' ||
          seeCriteria
        "
        href="mailto: support@evangelist.gg"
        class="inline-block bg-[#CFCBFF] rounded-full px-[15px] py-[8px] mt-6"
      >
        Contact support
      </a>

      <WalletsWalletConnector
        v-if="
          hasAdditionalWalletChecks &&
          campaign.eligibility.state !== 'NotEligibleBasic' &&
          seeCriteria === false
        "
        class="flex gap-2 bg-[#CFCBFF] rounded-full px-[15px] py-[8px] mt-8"
        :campaign="campaign"
        @saved="onWalletSaved"
        >Try another wallet</WalletsWalletConnector
      >
    </div>

    <div
      v-if="
        campaign.eligibility.state === 'Evaluating' ||
        campaign.eligibility.state === 'EvaluationPending'
      "
      class="text-md"
    >
      <div class="flex flax-wrap">
        <div class="text-black/50 flex items-center gap-3 flex-wrap">
          <BrandsIconAndName :theme="campaign?.advertiser?.theme" />
          <span class="w-1 h-1 rounded-full bg-black/50"></span>
          <CampaignDateInfo :campaign="campaign" />
          <span
            v-if="campaign.signupBonus > 0"
            class="w-1 h-1 rounded-full bg-black/50"
          ></span>
          <div
            v-if="campaign.apyBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-xs bg-[#CFCBFF] text-black"
          >
            <IconsShieldIcon class="mr-[4px] text-black fill-black" />
            Verified Members
          </div>
          <div
            v-if="campaign.signupBonus > 0"
            class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-black text-xs bg-gray-300"
          >
            {{ campaign.signupBonus }} {{ campaign.budgetCurrency }} Sign up
            Bonus
          </div>
        </div>
      </div>

      <div v-if="advertiserData" class="mt-6">
        <p
          :class="[i === 0 ? '' : 'mt-4']"
          v-for="(criteriaDescription, i) of advertiserData
            .eligibilityDescriptions.evaluating"
          :key="i"
        >
          {{ criteriaDescription }}
        </p>
      </div>
      <div
        v-if="campaign.eligibility.state === 'EvaluationPending'"
        class="mt-6"
      >
        <WalletsWalletConnector
          :campaign="campaign"
          @saved="onWalletSaved"
          class="flex gap-2 bg-[#CFCBFF] rounded-full px-[15px] py-[8px]"
          >Connect Wallet</WalletsWalletConnector
        >
      </div>

      <div
        v-if="campaign.eligibility.state === 'Evaluating'"
        class="flex gap-4 items-center mt-6"
      >
        <button class="flex gap-2 bg-gray-200 rounded-full px-[15px] py-[12px]">
          <EvangelistIcon class="h-[20px] w-[20px] animate-spin" />
          <span>Verifying...</span>
        </button>

        <WalletsWalletConnector
          class="text-gray-400"
          :campaign="campaign"
          @saved="onWalletSaved"
        >
          Try another wallet
        </WalletsWalletConnector>
      </div>
    </div>
  </div>
</template>
