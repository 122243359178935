<script setup lang="ts">
import PaydayModal from "../Global/PaydayModal.vue";

const defaultStore = useDefaultStore();

const paychecks = computed(() => {
  return defaultStore?.paychecks ?? [];
});

const paycheck = computed(() => {
  return paychecks.value?.[0] ?? null;
});
</script>

<template>
  <PaycheckModal
    v-if="paycheck && !paycheck.campaign.apyBonus"
    :paycheck="paycheck"
  />
  <PaydayModal
    v-if="paycheck && paycheck.campaign.apyBonus"
    :paycheck="paycheck"
  />
</template>
