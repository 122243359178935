<script setup lang="ts">
import FAQSidebar from "../Campaign/FAQSidebar.vue";

const faq = ref(false);

function handleSidebar() {
  faq.value = true;
}

onMounted(() => {
  globalOn("faqClicked", handleSidebar);
});

onBeforeUnmount(() => {
  globalOff("faqClicked", handleSidebar);
});
</script>

<template>
  <Sidebar v-if="faq" @close="faq = false">
    <FAQSidebar />
  </Sidebar>
</template>
