<script setup lang="ts">
import type { PayoutDto } from "~~/_api_generated_";

const opened = ref(true);

interface Props {
  paycheck: PayoutDto;
}
const props = defineProps<Props>();
const campaignId = ref(props.paycheck?.campaign.id);

function close() {
  opened.value = false;
}
</script>

<template>
  <LazyModalsPaydayModal
    v-if="opened && campaignId"
    :payout="props.paycheck"
    @close="close"
  />
</template>
